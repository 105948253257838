/* eslint-disable @typescript-eslint/no-explicit-any */

import classNames from "classnames";
import get from "lodash/get";
import React, { useCallback, useMemo, useState } from "react";

import { useAppSelector } from "stores";
import { selectAppBuilderErrors } from "stores/features/meta";
import useAppBuilderValidator from "utils/hooks/useAppBuilderValidator";

import Input, { InputChangeEvent, InputProps } from "./index";
import styles from "./styles.module.scss";

type Props = InputProps & {
  errorKey?: string;
};

const FormInput: React.FC<Props> = ({
  className,
  errorKey,
  onBlur,
  onChange,
  ...props
}) => {
  const errors = useAppSelector(selectAppBuilderErrors);
  const { validateAppBuilder } = useAppBuilderValidator();
  const [isEditing, setIsEditing] = useState(false);

  const errorMessage = useMemo(
    () => (errorKey ? get(errors, errorKey) : null),
    [errorKey, errors]
  );

  const handleBlur = useCallback(
    (id: string | undefined) => {
      setIsEditing(false);
      validateAppBuilder({ disableScroll: true });
      if (onBlur) onBlur(id);
    },
    [onBlur, validateAppBuilder]
  );

  const handleChange = useCallback(
    (event: InputChangeEvent<any>) => {
      setIsEditing(true);
      if (onChange) onChange(event as any);
    },
    [onChange]
  );

  return (
    <div className={styles.formInputContainer}>
      <Input
        className={classNames(
          styles.formInput,
          className,
          errorMessage && !isEditing && styles.error
        )}
        onBlur={handleBlur}
        onChange={handleChange}
        {...props}
      />
      {errorMessage && !isEditing && (
        <div className={styles.inputError}>{errorMessage}</div>
      )}
    </div>
  );
};

export default FormInput;
