/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import emailMisspelled, { Result, top100 } from "email-misspelled";
import * as EmailValidator from "email-validator";
import isString from "lodash/isString";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDebounce, useLocalStorage } from "react-use";
import isURL from "validator/lib/isURL";

import IconAndroid from "assets/images/icons/IconAndroid";
import IconClone from "assets/images/icons/IconClone";
import IconIos from "assets/images/icons/IconIos";
import Accordion from "components/common/Accordion";
import Button from "components/common/Button";
import Input from "components/common/Input";
import FormInput from "components/common/Input/FormInput";
import HookInput from "components/common/Input/HookInput";
import OrganizationDropDown from "components/common/OrganizationDropDown";
import Section from "components/common/Section";
import Subsection from "components/common/Subsection";
import Tooltip from "components/common/Tooltip";
import {
  generateRegex,
  getRootDomainUrlFromUrl,
} from "components/common/VisualEditor/utils";
import { useGetHelpInfoQuery } from "services/api/helpInfos";
import {
  ApiCreateProjectArgs,
  useCreateProjectMutation,
} from "services/api/projects";
import { useAppDispatch, useAppSelector } from "stores";
import { defaultAppIcon } from "stores/constants";
import {
  brandingUpdated,
  selectAppIcon,
} from "stores/features/appBuilder/branding";
import {
  linkHandlingUpdated,
  selectInternalVsExternalLinkItems,
  selectInternalVsExternalLinkItemsDefault,
} from "stores/features/appBuilder/linkHandling";
import {
  appBuilderOverviewAuthSchema,
  appBuilderOverviewSchema,
  AppBuilderOverviewState,
  overviewUpdated,
  selectOverview,
} from "stores/features/appBuilder/overview";
import { defaultSidebarItems } from "stores/features/appBuilder/sidebarNavigationBar";
import { selectIsBackupPreview } from "stores/features/backups";
import { selectCloneKey } from "stores/features/meta";
import {
  selectAndroidPackageName,
  selectIosBundleId,
  selectPrivateKey,
  selectPublicKey,
} from "stores/features/project";
import { selectDefaultSideBarMenuItems, uiUpdated } from "stores/features/ui";
import {
  selectIsAuthenticated,
  selectIsOtherOrganizationSelected,
  selectIsSuperUser,
  selectOtherOrganizationId,
  selectUserCurrentOrganizationId,
  userUpdated,
} from "stores/features/user";
import {
  OValue,
  ProjectSession,
  ProjectSource,
  trackEvent,
} from "utils/analytics";
import api from "utils/api";
import { SECTION } from "utils/constants";
import { handleError } from "utils/errors";
import { useCopyToClipboard, useImage, useIsLoaded } from "utils/hooks";
import { StorageEmail, storageKeys } from "utils/storage";
import urls, { toRelativeUrl } from "utils/urls";

import AppAccessUsers from "../Access/AppAccessUsers";
import styles from "./styles.module.scss";
import { getWebsiteDomain, getWebsiteTitle } from "./utils";

const Overview: React.FC = () => {
  const [createProject] = useCreateProjectMutation();

  const router = useRouter();
  const defaultWebsiteUrl = (router.query.url as string) || "";
  const [isCrawlingWebsite, setIsCrawlingWebsite] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const appIcon = useAppSelector(selectAppIcon);
  const signedAppIcon = useImage(appIcon);
  const dispatch = useAppDispatch();
  const cloneKey = useAppSelector(selectCloneKey);
  const overview = useAppSelector(selectOverview);
  const publicKey = useAppSelector(selectPublicKey);
  const privateKey = useAppSelector(selectPrivateKey);
  const iosBundleId = useAppSelector(selectIosBundleId);
  const androidPackageName = useAppSelector(selectAndroidPackageName);
  const currentOrganizationId = useAppSelector(selectUserCurrentOrganizationId);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isBackupPreview = useAppSelector(selectIsBackupPreview);
  const internalVsExternalLinkItems = useAppSelector(
    selectInternalVsExternalLinkItems
  );
  const internalVsExternalLinkItemsDefault = useAppSelector(
    selectInternalVsExternalLinkItemsDefault
  );

  const defaultSidebarMenuItems = useAppSelector(selectDefaultSideBarMenuItems);

  const isSuperUser = useAppSelector(selectIsSuperUser);

  const emailChecker = emailMisspelled({ domains: top100 });
  const [emailSuggestion, setEmailSuggestion] = useState<Result[] | null>(null);

  const isOtherOrganizationSelected = useAppSelector(
    selectIsOtherOrganizationSelected
  );
  const otherOrganizationId = useAppSelector(selectOtherOrganizationId);

  const copy = useCopyToClipboard();
  const { t } = useTranslation();

  const orgIdValue = useMemo(
    () =>
      isOtherOrganizationSelected ? otherOrganizationId : currentOrganizationId,
    [isOtherOrganizationSelected, otherOrganizationId, currentOrganizationId]
  );

  const [appToCloneName] = useState(overview.appName);

  const { control, handleSubmit, setError, setValue, watch } =
    useForm<AppBuilderOverviewState>({
      defaultValues: {
        appName: overview.appName,
        email: overview.email,
        websiteUrl: overview.websiteUrl || defaultWebsiteUrl,
      },
      resolver: yupResolver(
        currentOrganizationId
          ? appBuilderOverviewAuthSchema
          : appBuilderOverviewSchema
      ),
    });

  const watchWebsiteUrl = watch("websiteUrl");
  const isNewApp = !privateKey;
  const privateManagementUrl = urls.manage(privateKey);
  const publicPreviewUrl = urls.share(publicKey);
  const isPageLoaded = useIsLoaded();
  const [suggestedEmail, setSuggestedEmail] = useState("");

  const { isSuccess: isBackendUp } = useGetHelpInfoQuery();
  const [, setUserEmail] = useLocalStorage<StorageEmail>(storageKeys.email());

  const [appDomain, setAppDomain] = useState({ current: "", previous: "" });

  useEffect(() => {
    if (isNewApp) {
      setTimeout(() => {
        const inputId = watchWebsiteUrl
          ? "__overview-email-input"
          : "__overview-url-input";
        const input = document.getElementById(inputId);
        input?.focus();
      }, 0);
    }
  }, []);

  useEffect(() => {
    const mainContentElement = document.getElementById("__main-content");
    if (mainContentElement) {
      mainContentElement.scrollTo(0, 0);
    }
  }, []);

  const crawlIcon = useCallback(async () => {
    if (
      !isNewApp ||
      cloneKey ||
      isBackupPreview ||
      (appIcon &&
        appIcon !== defaultAppIcon &&
        appDomain.current === appDomain.previous)
    ) {
      return;
    }
    setAppDomain((prev) => ({ ...prev, previous: prev.current }));

    let icon: string | null = null;
    try {
      const response = await api.general.crawlIcon(watchWebsiteUrl);
      if (!response && !response[0]) return;
      icon = response[0].icon;
    } catch {
      // NO OP
    }

    dispatch(
      brandingUpdated({
        appIcon: {
          base: icon || undefined,
          ios: icon || undefined,
          android: icon || undefined,
        },
      })
    );
  }, [appIcon, cloneKey, dispatch, isNewApp, watchWebsiteUrl]);

  const crawlTitle = useCallback(async () => {
    if ((overview.appName && privateKey) || cloneKey || isBackupPreview) return;

    try {
      const response = await api.general.crawlWebsite(
        isNewApp ? watchWebsiteUrl : overview.websiteUrl
      );

      if (!response || !response[0]) {
        return;
      }

      const title = getWebsiteTitle(
        isNewApp ? watchWebsiteUrl : overview.websiteUrl,
        response[0].title
      );

      if (isNewApp || !overview.appName) {
        dispatch(overviewUpdated({ appName: title }));
        setValue("appName", title, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }

      if (!response[0].navOptions[0]) {
        return;
      }

      dispatch(
        uiUpdated({
          defaultSidebarMenuItems: response[0].navOptions[0].navLinks,
        })
      );
    } catch (e) {
      handleError(e, { t });
    }
  }, [
    dispatch,
    isNewApp,
    overview.appName,
    overview.websiteUrl,
    privateKey,
    setValue,
    t,
    watchWebsiteUrl,
  ]);

  const [, cancelCrawling] = useDebounce(
    async () => {
      if (!watchWebsiteUrl) {
        return;
      }

      if (isNewApp && !watchWebsiteUrl.startsWith("http")) {
        setValue("websiteUrl", `https://${watchWebsiteUrl}`);
        return;
      }

      if (isNewApp && !isURL(watchWebsiteUrl)) {
        return;
      }

      try {
        setIsCrawlingWebsite(true);
        await Promise.all([crawlIcon(), crawlTitle()]);
      } finally {
        setIsCrawlingWebsite(false);
      }
    },
    1500,
    [overview.websiteUrl, t, watchWebsiteUrl]
  );

  useDebounce(
    () => {
      const result = emailChecker(overview.email);
      setEmailSuggestion(result);
      if (result.length > 0) {
        setSuggestedEmail(result[0].corrected);
      } else {
        setSuggestedEmail("");
      }
    },
    500,
    [overview.email]
  );

  const showSuggestedEmail = useMemo(() => {
    if (!suggestedEmail) return undefined;
    const [localPart, domain] = (suggestedEmail as string).split("@");
    return (
      <div className={styles.suggestedEmailContainer}>
        <span>
          {t("fields.validation.mispelledEmail", {
            email: `${localPart}@`,
          })}
          <strong>{domain}</strong>?
        </span>
        <span
          className={styles.emailButton}
          onClick={() => {
            dispatch(overviewUpdated({ email: suggestedEmail }));
            setValue("email", suggestedEmail, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
          onKeyDown={() => null}
          role="button"
          tabIndex={0}
        >
          {t("common.yes")}
        </span>
      </div>
    );
  }, [overview.email, suggestedEmail]);

  const handleWebsiteUrlChange = useCallback((output) => {
    dispatch(overviewUpdated({ websiteUrl: output.value }));

    const hasDomainPagesToTrigger = internalVsExternalLinkItems.some(
      (item) => item.pagesToTrigger === "domain"
    );

    const hasDomainPagesToTriggerDefault =
      internalVsExternalLinkItemsDefault.some(
        (item) => item.pagesToTrigger === "domain"
      );

    if (!hasDomainPagesToTrigger && !hasDomainPagesToTriggerDefault) return;

    const nextRegex = generateRegex({
      url: output.value ? getRootDomainUrlFromUrl(output.value) : "",
      includeSubdomains: true,
      isSinglePage: false,
    });

    const updatedLinkHandlingValue = internalVsExternalLinkItems.map((item) => {
      if (item.pagesToTrigger === "domain") {
        return {
          ...item,
          regex: nextRegex,
        };
      }
      return item;
    });

    const updatedLinkHandlingValueDefault =
      internalVsExternalLinkItemsDefault.map((item) => {
        if (item.pagesToTrigger === "domain") {
          return {
            ...item,
            regex: nextRegex,
          };
        }
        return item;
      });

    dispatch(
      linkHandlingUpdated({
        internalVsExternalLinks: {
          active: true,
          items: updatedLinkHandlingValue,
          itemsDefault: updatedLinkHandlingValueDefault,
        },
      })
    );
  }, []);

  const handleSubmitInternal: SubmitHandler<AppBuilderOverviewState> = async (
    data
  ) => {
    if (!currentOrganizationId && !EmailValidator.validate(data.email)) {
      setError("email", { type: "email" });
      return;
    }
    if (emailSuggestion && emailSuggestion?.length > 0) return;
    if (isCreating) return;
    setIsCreating(true);
    cancelCrawling();

    let sValue;
    let oValue: OValue | null = null;

    if (
      typeof window !== "undefined" &&
      window &&
      window.localStorage &&
      typeof window.localStorage !== "undefined"
    ) {
      sValue = localStorage.getItem("s")
        ? localStorage.getItem("s")
        : router.query.s;

      oValue = JSON.parse(localStorage.getItem("o") || "null");
    } else {
      sValue = router.query.s;
    }

    // Create project session object from 'o' value in localStorage
    let projectSession: ProjectSession | undefined;
    if (oValue) {
      projectSession = {
        referrer: oValue.r,
        initialPath: oValue.u,
        timestamp: oValue.t, // in milliseconds (ex: 1733254380350)
        gclid: oValue.g,
        msclkid: oValue.m,
        // only include the below fields if value is NOT undefined
        ...(oValue.utm_campaign && { utm_campaign: oValue.utm_campaign }),
        ...(oValue.utm_medium && { utm_medium: oValue.utm_medium }),
        ...(oValue.utm_source && { utm_source: oValue.utm_source }),
        ...(oValue.utm_term && { utm_term: oValue.utm_term }),
      };
    }

    let source: ProjectSource;
    if (sValue === "1") {
      source = "GPS";
    } else if (sValue === "2") {
      source = "GOS";
    } else if (sValue === "3") {
      source = "BPS";
    } else if (sValue === "4") {
      source = "BOS";
    } else if (sValue === "6") {
      source = "YOS";
    } else if (sValue === "7") {
      source = "LIP";
    } else if (sValue === "8") {
      source = "LIO";
    } else if (defaultWebsiteUrl.length > 0) {
      source = "HPD";
    } else {
      source = "DIR";
    }

    let sourceSelection: string | undefined;
    if (isString(router.query.p) && router.query.p.length < 5) {
      sourceSelection = router.query.p.toUpperCase();
    }

    let sourcePage: string | undefined;
    if (isString(router.query.l) && router.query.l.length < 5) {
      sourcePage = router.query.l.toUpperCase();
    }

    if (data.email) {
      setUserEmail({ email: data.email });
    }

    try {
      const createProjectData: ApiCreateProjectArgs = {
        appName: data.appName,
        email: data.email,
        initialUrl: data.websiteUrl,
        organizationId: orgIdValue,
        sidebarItems: defaultSidebarItems(defaultSidebarMenuItems),
        source,
        sourcePage,
        sourceSelection,
        projectSession,
      };
      if (cloneKey) {
        createProjectData.clonePublicKey = cloneKey;
      } else if (appIcon && appIcon !== defaultAppIcon) {
        createProjectData.appIcon = appIcon;
      }

      const response = await createProject(createProjectData).unwrap();

      if (!isAuthenticated) {
        trackEvent("get_user", {
          email: response.appBuilder.appBuilder.overview.email,
        });
      }

      window.location.href = urls.appBuilder(
        response.appBuilder.project.privateKey,
        SECTION.branding
      );
    } catch (error) {
      handleError(error, { t });
      setIsCreating(false);
    }
  };

  return (
    <Section
      configKey="overview"
      section={SECTION.overview}
      hideHeader={!privateKey}
    >
      <form onSubmit={handleSubmit(handleSubmitInternal)}>
        {cloneKey && !privateKey && (
          <Accordion disableCollapse title={t("sections.overview.appToClone")}>
            <Subsection>
              <div className={styles.appToClone}>
                <img
                  alt=""
                  className={styles.appToCloneImage}
                  src={toRelativeUrl(signedAppIcon || defaultAppIcon)!}
                  height={32}
                  width={32}
                />
                <div className={styles.nameAndPackageContainer}>
                  <div>{appToCloneName}</div>
                  <div className={styles.packageContainers}>
                    <div className={styles.iosContainer}>
                      <IconIos />
                      <span className={styles.packageText}>
                        {iosBundleId || `co.median.ios.${publicKey}`}
                      </span>
                    </div>
                    <div className={styles.iosContainer}>
                      <IconAndroid />
                      <span className={styles.packageText}>
                        {androidPackageName || `co.median.android.${publicKey}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Subsection>
          </Accordion>
        )}

        <Accordion
          configKey="overview.websiteUrl"
          disableCollapse={!privateKey}
          title={t("sections.overview.websiteUrl")}
          className={classNames(!privateKey && styles.accordionNewApp)}
        >
          <Subsection>
            {isNewApp ? (
              <HookInput
                control={control}
                loading={isCrawlingWebsite}
                name="websiteUrl"
                onChange={(output) => {
                  dispatch(overviewUpdated({ websiteUrl: output.value }));
                  const domain = getWebsiteDomain(output.value);
                  if (domain) {
                    setAppDomain((prev) => ({ ...prev, current: domain }));
                  }
                }}
                placeholder="https://example.com"
                required
                type="url"
                value={overview.websiteUrl}
                disabled={!isPageLoaded}
                className={styles.newAppForm}
                id="__overview-url-input"
              />
            ) : (
              <FormInput
                errorKey="overview.websiteUrl"
                loading={isCrawlingWebsite}
                name="websiteUrl"
                onChange={handleWebsiteUrlChange}
                placeholder="https://example.com"
                required
                type="url"
                value={overview.websiteUrl}
              />
            )}
          </Subsection>
        </Accordion>

        <Accordion
          configKey="overview.appName"
          disableCollapse={!privateKey}
          title={t("sections.overview.appName")}
          className={classNames(!privateKey && styles.accordionNewApp)}
        >
          <Subsection>
            {isNewApp ? (
              <HookInput
                autoCapitalize="words"
                autoCorrect="off"
                control={control}
                name="appName"
                onChange={(output) =>
                  dispatch(overviewUpdated({ appName: output.value }))
                }
                placeholder={t("sections.overview.placeholderAppName")}
                required
                type="text"
                value={overview.appName}
                disabled={!isPageLoaded}
                className={styles.newAppForm}
              />
            ) : (
              <FormInput
                autoCapitalize="words"
                autoCorrect="off"
                errorKey={overview.appName ? undefined : "overview.appName"}
                name="appName"
                onChange={(output) =>
                  dispatch(overviewUpdated({ appName: output.value }))
                }
                placeholder={t("sections.overview.placeholderAppName")}
                required
                type="text"
                value={overview.appName}
              />
            )}
          </Subsection>
        </Accordion>

        {!isNewApp && (
          <Accordion
            configKey="overview.description"
            disableCollapse={!privateKey}
            title={t("sections.overview.description")}
            className={classNames(!privateKey && styles.accordionNewApp)}
          >
            <Subsection>
              <FormInput
                autoCapitalize="words"
                autoCorrect="off"
                errorKey="overview.description"
                name="description"
                onChange={(output) =>
                  dispatch(overviewUpdated({ description: output.value }))
                }
                placeholder={t("sections.overview.placeholderDescription")}
                type="textarea"
                value={overview.description}
              />
            </Subsection>
          </Accordion>
        )}

        {isNewApp && (
          <Accordion
            configKey={
              currentOrganizationId ? "overview.organization" : "overview.email"
            }
            disableCollapse={!privateKey}
            title={
              currentOrganizationId
                ? t("sections.overview.organization")
                : t("sections.overview.contact")
            }
            className={classNames(!privateKey && styles.accordionNewApp)}
            contentClassName={classNames(
              currentOrganizationId && styles.dropdownContent
            )}
          >
            <Subsection>
              {currentOrganizationId ? (
                <>
                  <OrganizationDropDown
                    className={classNames(isNewApp && styles.newAppForm)}
                  />
                  {isSuperUser && (
                    <Input
                      onChange={(output) => {
                        dispatch(
                          userUpdated({
                            otherOrganization: {
                              isOtherOrganization: true,
                              orgId: output.value,
                            },
                          })
                        );
                      }}
                      required={isOtherOrganizationSelected}
                      placeholder={t("containers.auth.organizationId")}
                      type="text"
                      value={orgIdValue}
                      className={styles.newAppForm}
                    />
                  )}
                </>
              ) : (
                <div className={styles.emailContainer}>
                  <HookInput
                    control={control}
                    name="email"
                    onChange={(output) => {
                      dispatch(overviewUpdated({ email: output.value }));
                    }}
                    placeholder="name@example.com"
                    required={!currentOrganizationId}
                    type="email"
                    value={overview.email}
                    disabled={!isPageLoaded}
                    className={styles.newAppForm}
                    id="__overview-email-input"
                  />
                  {showSuggestedEmail && showSuggestedEmail}
                </div>
              )}
            </Subsection>
          </Accordion>
        )}

        {!isNewApp && (
          <Accordion
            configKey="overview.privateManagementUrl"
            title={t("sections.overview.privateManagementUrl")}
          >
            <Subsection>
              <span className={styles.itemContainer}>
                <Button
                  className={styles.url}
                  href={privateManagementUrl}
                  target="_self"
                  type="link"
                >
                  {privateManagementUrl}
                </Button>

                <Tooltip label={t("common.copyToClipboard")}>
                  <Button
                    className={styles.copyButton}
                    type="text"
                    onClick={() => copy(privateManagementUrl)}
                    title={t("common.copyToClipboard")}
                  >
                    <IconClone />
                  </Button>
                </Tooltip>
              </span>
            </Subsection>
          </Accordion>
        )}

        {!isNewApp && (
          <Accordion
            configKey="overview.publicSharingUrl"
            title={t("sections.overview.publicSharingUrl")}
          >
            <Subsection>
              <span className={styles.itemContainer}>
                <Button
                  className={styles.url}
                  href={publicPreviewUrl}
                  target="_blank"
                  type="link"
                  newWindow
                >
                  {publicPreviewUrl}
                </Button>

                <Tooltip label={t("common.copyToClipboard")}>
                  <Button
                    className={styles.copyButton}
                    type="text"
                    onClick={() => copy(publicPreviewUrl)}
                    title={t("common.copyToClipboard")}
                  >
                    <IconClone />
                  </Button>
                </Tooltip>
              </span>
            </Subsection>
          </Accordion>
        )}

        {isNewApp && (
          <Accordion
            disableCollapse
            className={classNames(!privateKey && styles.accordionNewApp)}
          >
            <div>
              <Button
                disabled={isCreating || !isBackendUp}
                loading={isCreating}
                className={styles.button}
                isSubmit
                loaderColor="#ffffff"
                size="lg"
                type="primary"
              >
                {cloneKey
                  ? t("sections.overview.clone")
                  : t("sections.overview.build")}
              </Button>
            </div>
          </Accordion>
        )}

        {!isNewApp && overview.organizationName && (
          <Accordion
            configKey="overview.accessManagement"
            title={t("sections.overview.accessManagement")}
            disableCollapse
            withoutOverflow
          >
            <Subsection>
              <AppAccessUsers />
            </Subsection>
          </Accordion>
        )}
      </form>
    </Section>
  );
};

export default Overview;
