import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useEffect, useMemo } from "react";

import Select from "components/common/Select";
import { useSwitchCurrentOrganizationMutation } from "services/api/organization";
import { useAppDispatch, useAppSelector } from "stores";
import {
  organizationUpdated,
  selectTransferAppOrganizationId,
} from "stores/features/organization";
import {
  selectIsAuthenticated,
  selectIsOtherOrganizationSelected,
  selectIsSuperUser,
  selectOtherOrganizationId,
  selectUserCurrentOrganizationId,
  selectUserOrganizations,
  userUpdated,
} from "stores/features/user";
import { useOrganizationDetails } from "utils/hooks";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
  mode?: "select" | "switch";
  excludeOrgId?: string;
}

const OrganizationDropDown: React.FC<Props> = ({
  className,
  mode,
  excludeOrgId,
}) => {
  const [switchOrganization] = useSwitchCurrentOrganizationMutation();
  const isSuperUser = useAppSelector(selectIsSuperUser);
  const otherOrganizationId = useAppSelector(selectOtherOrganizationId);
  const isOtherOrganizationSelected = useAppSelector(
    selectIsOtherOrganizationSelected
  );

  const { t } = useTranslation();
  const organizations = useAppSelector(selectUserOrganizations);
  const { organizationId } = useOrganizationDetails();
  const userCurrentOrganizationId = useAppSelector(
    selectUserCurrentOrganizationId
  );
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const transferToAppOrganizationId = useAppSelector(
    selectTransferAppOrganizationId
  );
  const dispatch = useAppDispatch();

  const currentOrganizationId = organizationId || userCurrentOrganizationId;

  const selectOptions = useMemo(() => {
    let filteredOrganizations = organizations;

    if (mode === "select") {
      filteredOrganizations = filteredOrganizations.filter(
        (item) => item.id !== organizationId && item.id !== excludeOrgId
      );
    }

    const options = filteredOrganizations.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    if (mode !== "select" && isSuperUser) {
      options.push({
        label: t("common.other"),
        value: "other",
      });
    }

    const uniqueOptions: { label: string; value: string }[] = Array.from(
      new Set(options.map((item) => item.value))
    )
      .map((uniqueValue) => options.find((item) => item.value === uniqueValue))
      .filter(
        (item): item is { label: string; value: string } => item !== undefined
      );

    if (uniqueOptions.length === 0) {
      return [{ label: "", value: "" }];
    }

    return uniqueOptions;
  }, [mode, organizations, organizationId, excludeOrgId, isSuperUser, t]);

  useEffect(() => {
    if (transferToAppOrganizationId || selectOptions.length === 0) return;
    dispatch(
      organizationUpdated({ transferAppOrganizationId: selectOptions[0].value })
    );
  }, [dispatch, selectOptions, transferToAppOrganizationId]);

  const selectValue = useMemo(() => {
    if (mode === "select") {
      if (selectOptions.length === 0) return undefined;
      const item = transferToAppOrganizationId
        ? organizations.find((i) => i.id === transferToAppOrganizationId)
        : organizations.find((i) => i.id === selectOptions[0].value);
      if (!item) return undefined;

      return { label: item.name, value: item.id };
    }

    const item = organizations.find((i) => i.id === organizationId);
    if (!item) return undefined;
    return { label: item.name, value: item.id };
  }, [
    mode,
    organizationId,
    organizations,
    selectOptions,
    transferToAppOrganizationId,
  ]);

  const switchValue = useMemo(() => {
    if (isOtherOrganizationSelected)
      return {
        label: t("common.other"),
        value: "other",
      };

    const item = organizations.find((i) => i.id === otherOrganizationId);
    if (!item) return undefined;
    return { label: item.name, value: item.id };
  }, [isOtherOrganizationSelected, organizations, otherOrganizationId, t]);

  const defaultValue = useMemo(() => {
    const item = organizations.find((i) => i.id === currentOrganizationId);
    if (!item) return undefined;
    return { label: item.name, value: item.id };
  }, [currentOrganizationId, organizations]);

  const handleChange = useCallback(
    (newOrg) => {
      dispatch(
        userUpdated({
          otherOrganization: {
            orgId: newOrg.value === "other" ? "" : newOrg.value,
            isOtherOrganization: newOrg.value === "other",
          },
        })
      );
      if (newOrg.value === "other") return;

      dispatch(
        userUpdated({
          organizations: organizations.map((organization) => ({
            ...organization,
            isCurrentOrganization: organization.id === newOrg.value,
          })),
        })
      );

      try {
        switchOrganization(newOrg.value);
      } catch {
        // NO OP
      }
    },
    [dispatch, organizations, switchOrganization]
  );

  const handleSelectChange = useCallback(
    (newOrg) => {
      dispatch(
        organizationUpdated({ transferAppOrganizationId: newOrg.value })
      );
    },
    [dispatch]
  );

  if (!isAuthenticated) {
    return null;
  }

  if (mode === "select") {
    return (
      <Select
        className={classNames(styles.select, className)}
        isSearchable={false}
        onChange={handleSelectChange}
        options={selectOptions}
        placeholder={t("containers.auth.selectOrganization")}
        value={selectValue}
        menuPlacement="auto"
      />
    );
  }

  return (
    <Select
      className={classNames(styles.select, className)}
      isSearchable={false}
      onChange={handleChange}
      options={selectOptions}
      defaultValue={defaultValue}
      placeholder={t("containers.auth.selectOrganization")}
      value={switchValue}
      menuPlacement="auto"
    />
  );
};

export default OrganizationDropDown;
