/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-constant-condition */

import { useMemo, useState } from "react";

import { useAppSelector } from "stores";
import { selectBottomTabBar } from "stores/features/appBuilder/bottomTabBar";
import { selectBranding } from "stores/features/appBuilder/branding";
import { selectTopNavigationBar } from "stores/features/appBuilder/topNavigationBar";

import {
  MenuBarItem,
  MenusItem,
  SelectionConfigItem,
  VisualEditorOptions,
} from "./constants";

export const getRootDomainUrlFromUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const hostParts = parsedUrl.hostname.split(".");
    const hostPartsSliced =
      hostParts.length > 3 ? hostParts.slice(-3) : hostParts.slice(-2);
    const rootDomain = hostPartsSliced.join(".");

    return `${parsedUrl.protocol}//${rootDomain}`;
  } catch (error) {
    return url;
  }
};

export const generateRegex = (source: {
  url: string;
  includeSubdomains?: boolean;
  includeDomain?: boolean;
  isSinglePage?: boolean;
}) => {
  const { url, includeSubdomains, includeDomain, isSinglePage } = source;

  const subdomainRegex = includeSubdomains ? "([-\\w]+\\.)*" : "(w{3}\\.)?";

  let domain = url.substring(url.indexOf("/") + 2);

  if (!includeDomain) {
    domain = domain.replace("www.", "");
    if (domain.endsWith("/")) domain = domain.slice(0, -1);
    domain = domain.replace(/[/|\\{}()[\]^$+*?]/g, "\\$&");
  }

  const suffix = isSinglePage ? "\\/?$" : "(\\/.*)?$";

  return `https?:\\/\\/${subdomainRegex}${domain}${suffix}`;
};

export const getNewMenuId = (
  tabMenus: MenusItem[],
  tabSelectionConfig: SelectionConfigItem[]
) => {
  let index = 1;
  while (true) {
    const id = `${index}`;
    if (
      !tabMenus.find((item) => item.id === id) &&
      !tabSelectionConfig.find((item) => item.id === id)
    ) {
      return id;
    }
    index += 1;
  }
};

export const getMenuItemId = (index: number, item: MenuBarItem) => {
  const { icon, label, url } = item;
  return `${index}${icon || ""}${label || ""}${url || ""}`;
};

export const getClonedLabel = (
  clonedLabel: string | undefined,
  tabSelectionConfig: SelectionConfigItem[]
) => {
  let count = 2;
  let label = `${clonedLabel} (copy)`;
  while (true) {
    if (!tabSelectionConfig.some((item) => item.label === label)) {
      return label;
    }
    label = `${clonedLabel} (copy ${count})`;
    count += 1;
  }
};

export const isValidSystem = (system = "") =>
  ["refresh", "search", "share"].includes(system);

export const isDefaultButtonType = (type = "") =>
  !["javascript", "refresh", "search", "share"].includes(type);

export const useVisualEditorOptions = (): VisualEditorOptions => {
  const branding = useAppSelector(selectBranding);
  const bottomTabBar = useAppSelector(selectBottomTabBar);
  const topNavigationBar = useAppSelector(selectTopNavigationBar);

  const [device, setDevice] = useState<"ios" | "android">("ios");
  const [darkMode, setDarkMode] = useState(false);

  const bottomTabBarStyling = useMemo(() => {
    if (device === "android") {
      if (darkMode) {
        return {
          backgroundColor: bottomTabBar.styling?.android?.backgroundColorDark,
          activeColor: bottomTabBar.styling?.android?.indicatorColorDark,
          inactiveColor: bottomTabBar.styling?.android?.textColorDark,
        };
      }
      return {
        backgroundColor: bottomTabBar.styling?.android?.backgroundColor,
        activeColor: bottomTabBar.styling?.android?.indicatorColor,
        inactiveColor: bottomTabBar.styling?.android?.textColor,
      };
    }
    if (darkMode) {
      return {
        backgroundColor: bottomTabBar.styling?.ios?.tintColorDark,
        activeColor: branding.themeColors?.ios?.primaryColorDark,
        inactiveColor: bottomTabBar.styling?.ios?.inactiveColorDark,
      };
    }
    return {
      backgroundColor: bottomTabBar.styling?.ios?.tintColor,
      activeColor: branding.themeColors?.ios?.primaryColor,
      inactiveColor: bottomTabBar.styling?.ios?.inactiveColor,
    };
  }, [bottomTabBar.styling, branding.themeColors, darkMode, device]);

  const topNavigationBarStyling = useMemo(() => {
    if (device === "android") {
      if (darkMode) {
        return {
          backgroundColor:
            topNavigationBar.styling?.android?.backgroundColorDark,
          tintColor: topNavigationBar.styling?.android?.textColorDark,
        };
      }
      return {
        backgroundColor: topNavigationBar.styling?.android?.backgroundColor,
        tintColor: topNavigationBar.styling?.android?.textColor,
      };
    }
    if (darkMode) {
      return {
        backgroundColor: topNavigationBar.styling?.ios?.tintColorDark,
        tintColor: topNavigationBar.styling?.ios?.textColorDark,
      };
    }
    return {
      backgroundColor: topNavigationBar.styling?.ios?.tintColor,
      tintColor: topNavigationBar.styling?.ios?.textColor,
    };
  }, [topNavigationBar.styling, darkMode, device]);

  return {
    darkMode,
    setDarkMode,
    device,
    setDevice,
    bottomTabBarStyling,
    topNavigationBarStyling,
  };
};

export const useTopNavTitleOptions = (options: {
  device: "ios" | "android";
  darkMode: boolean;
}) => {
  const branding = useAppSelector(selectBranding);
  const topNavigationBar = useAppSelector(selectTopNavigationBar);

  const { device, darkMode } = options;

  const { newImage, titleColor } = useMemo(() => {
    if (device === "android") {
      if (darkMode) {
        return {
          newImage: topNavigationBar.styling?.android?.newImageDark,
          titleColor: topNavigationBar.styling?.android?.textColorDark,
        };
      }
      return {
        newImage: topNavigationBar.styling?.android?.newImage,
        titleColor: topNavigationBar.styling?.android?.textColor,
      };
    }
    if (darkMode) {
      return {
        newImage: topNavigationBar.styling?.ios?.newImageDark,
        titleColor: topNavigationBar.styling?.ios?.textColorDark,
      };
    }
    return {
      newImage: topNavigationBar.styling?.ios?.newImage,
      titleColor: topNavigationBar.styling?.ios?.textColor,
    };
  }, [
    darkMode,
    device,
    topNavigationBar.styling?.android,
    topNavigationBar.styling?.ios,
  ]);

  const { appIcon, imageType } = useMemo(() => {
    if (device === "android") {
      return {
        appIcon: branding.appIcon?.android,
        imageType: topNavigationBar.styling?.android?.imageType,
        newImage: topNavigationBar.styling?.android?.newImage,
      };
    }
    return {
      appIcon: branding.appIcon?.ios,
      imageType: topNavigationBar.styling?.ios?.imageType,
      newImage: topNavigationBar.styling?.ios?.newImage,
    };
  }, [
    branding.appIcon?.android,
    branding.appIcon?.ios,
    topNavigationBar.styling?.android,
    topNavigationBar.styling?.ios,
    device,
  ]);

  return { newImage, titleColor, appIcon, imageType };
};
